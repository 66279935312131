.accordion {
    width: 100%;
    margin: 0px 0px 10px 0px;
    padding: 0px 0px 0px 0px;
}

.accordion-header {
    height: auto;
}

.discord>.accordion>.accordion-item>.accordion-collapse>.accordion-body {
    padding: 0px;
    margin: 0px;
}

.accordion-body {
    padding: 15px;
    margin: 0px;
}

.accordion-button {
    height: auto;
    background-color: #F5F5F5 !important;
    padding: 10px 10px 10px 20px;
    margin: 0px;
}

.accordion-button:not(.collapsed) {
    color: black;
}